.data-list-container {
    padding: 16px;
}

.get-button,
.delete-button {
    margin-bottom: 16px;
    padding: 8px 16px;
    background-color: #3b82f6;
    color: white;
    border: none;
    border-radius: 0.375rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    margin: 0.5rem;
}

.get-button:hover {
    background-color: #2563eb;
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.2);
}

.error-message {
    color: #ef4444;
}

.data-list {
    max-width: 35rem;
    /* background-color: rgb(75, 75, 108); */
    list-style-type: none;
    padding: 0;
}

.data-item {
    padding: 16px;
    background-color: rgb(70, 70, 101);
    border-radius: 15px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 12px;
    transition: box-shadow 0.3s;
}

.data-item.highlighted {
    border: 2px solid yellow;
    background-color: rgb(103, 103, 162);
}


.data-item:hover {
    box-shadow: 0 5px 9px rgba(0, 0, 0, 0.2);
}

.item-names {
    font-size: 1.25rem;
    font-weight: 600;
    margin-bottom: 8px;
}

.data-pr{
    background-color: rgb(90, 90, 126);
    border-radius: 10px;
    padding: 1.5rem;
    font-size: 1.5rem;
}

.data-phone-num{
    font-size: 0.75rem;
}

#buttons-list-container{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0.5rem;
    border-radius: 10px;
    padding: 1rem;
    background-color: rgb(88, 88, 124);
}