#current-language-text-element{
    cursor:auto;
    color: lightgray;;
}

/* used to be 601 and 1024px */
@media (min-width: 601px) and (max-width: 1370px) {

    .basic-nav-dropdown {
        color: red;
    }


}