.live-banner {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(255, 0, 0, 0.9);
    color: white;
    padding: 10px 20px;
    border-radius: 8px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    box-shadow: 0 0 15px rgba(255, 0, 0, 0.6);
    z-index: 1000;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .live-banner h2 {
    margin: 0;
    padding-bottom: 10px;
  }
  
  .live-banner iframe {
    width: 100%;
    max-width: 560px;
    height: 315px;
    border-radius: 8px;
  }
  
  /* Mobile-friendly adjustments */
  @media (max-width: 600px) {
    .live-banner {
      width: 90%;
      font-size: 16px;
      padding: 8px;
    }
  
    .live-banner iframe {
      height: 200px;
    }
  }
  