.App {
  text-align: center;
}

#wholepage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#top-page{
  width: 100%;
  display: flex;
  height: 600px;
  background-image: url("../../images/churchbig2.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: white;
  justify-content: center;
  align-items: center;
  font-family: "sinter", sans-serif;
  font-weight: 700;
  font-style: normal;

  text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);

}

#headerbutton{
  background-color: white;

  /* background-color: darkblue; */
  border-radius: 10px;
  border-style: none;
  padding: 15px;
}

#middle-page{
  padding: 50px;
  color: white;
  background-image: linear-gradient(#3b6b94, #7796b1);

  /* background-color: #3b6b94; */
  display: flex;
  width: 100%;
  gap: 8rem;
  justify-content: center;
}

#middle-page p, li {
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

}

/* Whatever element has this class, the text will not be able to be selected and/or highlighted */
.no-select {
  user-select: none; 
  -webkit-user-select: none; 
  -moz-user-select: none;
  -ms-user-select: none; 
}


/* FOR EVERYTHING MOBILE RESPONSIVENESS */
@media (max-width: 600px) {

  #socialdiv {
    margin: 0;
    width: 100%;
    padding-top: 1rem !important;
    /* margin-right: 400rem !important; */
    margin-top: 1rem;
    align-items: center;
    justify-content: center;
    font-size: 10rem !important;
  }

  #custom-verse{
    /* I'm thinking it's best to hide this if you're on mobile. Maybe I change my mind later. */
    display: none; 
    /* padding: 3rem;
    text-align: center; */
  }

  /* #wholepage {
    width: 100%;
  } */

  #top-page {
    height: 30rem;
  }

  h1 {
    font-size: 4rem !important;
    margin-top: 4rem;
    
    
  }

  #middle-page {
    display: block;
    width: 100% !important;
  }

  #youtube {
    width: 75%;
    height: auto;
  }

  #googleapi {
    width: 90%;
  }

  #bottom-page {
    display: block !important;
  }

  #googlemap{
    width: 90%;
    height: auto;
  }

  #footerid {
    display: block !important;
  }

  .social {
    padding: 1.5rem !important;
    /* border: 1px solid white; */
  }

  #sample-svg {
    height: 0.3rem;
    width: auto;
  }

  #connect-header-title{
    font-size: 0.75rem;
  }

  #notification-caption {
    font-size: 0.80rem !important;
  }

  #carousel-container-id{
    margin-bottom: 1.5rem;
  }

}



#bottom-page{
  width: 100%;
  text-align: center;
  display: flex;
  color: #3D2B1F;
  background-image: linear-gradient(#cdc3ac, #ab986b);

  /* background-color: #c7bca2; */
  padding: 25px;
  padding-bottom: 135px;
  /* gap: 150px;   */
  gap: 1.5rem;
  justify-content: center;
  font-family: "sinter", sans-serif;
  font-weight: 700;
  font-style: normal;

}

#middle-page h4 {
  font-family: "sinter", sans-serif;
font-weight: 700;
font-style: normal;
text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);

}

#middle-page h5 {
  font-family: "sinter", sans-serif;
font-weight: 700;
font-style: normal;
text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.3);
color: yellow;
}

#googlemap {
  border-radius: 8px;
  border: 3px solid whitesmoke;
  box-shadow: 6px 6px 6px black;
}

footer {
  text-align:start;
  color: #EEE3CB;
  background-color: #3D2B1F;
  padding: 40px;
}

#footerid {
  display: flex;
  gap: 40px;
}

#footerfont{
  font-family: "sinter", sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 30px;
}

#socialdiv {
  display: flex;
  padding: 5px;
  gap: 15px;
}

.social {
  border: 1px solid white;
  border-radius: 50%;
  padding: 1rem;
  transition: transform 0.2s;
  box-shadow: 1px 1px 5px black;
}

@keyframes flipIn {
  0% {
    transform: rotateY(-90deg);
    opacity: 0;
  }
  100% {
    transform: rotateY(0deg);
    opacity: 1;
  }
}

#titleh1 {
  animation: flipIn 2s ease-out;

}

#custom-verse{
  animation: flipIn 1s ease-out;
  color: white;
  /* color: rgb(218, 218, 218); */
}

.social:hover{
  transform: scale(1.3);
}

#youtube-title {
  padding: 20px;
  padding-bottom: 5px;
  /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); */

}


#calendar-title {
  padding: 20px;
  /* text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3); */

}

#youtube {
  border-radius: 25px;
  box-shadow: 3px 3px 6px black;
  border: 1px solid #3D2B1F;
}


body {
  height: 100vh;
}

#caro{
  
  max-width: 1000px;
}

h1 {
  font-size: 75px;
}

h1,
h3 {
    text-align: center;
}

h3 {
  padding-bottom: 10px;
}

.mt-3 {
  color: red;
}
.nav {
  background-color: white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

#googleapi {
  box-shadow: 3px 3px 6px black;
  border-radius: 5px;
  border: 1px solid #3D2B1F !important;
}




/* this is for animation when scrolling onto certain divs */
.scroll-animation {
  opacity: 0;
  transform: translateY(50px); 
  transition: opacity 0.5s ease-out, transform 0.5s ease-out; 
}

.scroll-animation.in-view {
  opacity: 1;
  transform: translateY(0); 
}

/* For the library of prediacciones button*/
#service-library-button {
  color: #3D2B1F;
  background-color: #d8ccae;
  border: 1px solid #d8ccae;
  border-radius: 8px;
  padding: 1rem;
  margin-top: 1.5rem;
  box-shadow: 2px 2px 2px #b5ab92;
  min-width: 25%;
}

#service-library-button:hover {
  background-color: #e6d9ba;
}

#bottom-left-div{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


/* FOR TABLET / IPAD SCREEN WIDTHS */
@media (min-width: 601px) and (max-width: 1024px) {

  /* h1 {
    color: red;
  } */

  #middle-page{
    flex-direction: column;
    align-items: center;
    gap: 3rem;
  }

  #bottom-page {
    flex-direction: column;
    gap: 2rem;
  }

}

#sub-head-title-for-vid {
  margin-bottom: 1rem;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;

  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);

}

#notification-caption {
  /* color: black; */
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.75rem;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  /* width: 25rem; */
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.3);
}


/* JUST FOR CALENDAR SUBSCRIBE BUTTON */
.subscribeButton {
  background-color: #4CAF50; 
  color: #fff; 
  padding: 12px 24px;
  /* border: none; */
  border: 1px solid rgb(201, 201, 201);
  border-radius: 25px;
  font-size: 1rem;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.3s ease;
  outline: none;
}

.subscribeButton:hover {
  background-color: #45A049; 
  transform: scale(1.05); 
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.subscribeButton:focus {
  box-shadow: 0 0 0 3px rgba(76, 175, 80, 0.4); 
}




















