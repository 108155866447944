.dropdown07 {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-button17 {
    background-color: #DFD0B8;
    color: #153448;
    padding: 0.5rem 3rem;
    font-size: 16px;
    border-radius: 25px;
    border: 10px solid #3C5B6F;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .dropdown-button17:hover {
    background-color: #c9b79b;
    color: #3C5B6F;
}
  
  .arrow7 {
    margin-left: 8px;
  }
  
  .dropdown-list27 {
    position: absolute;
    /* background-color: white;
    border: 1px solid #ccc; */
    background-color: #DFD0B8;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-left: 1rem;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    padding: 0;
    list-style-type: none;
    z-index: 1; /* Ensure it appears on top of other content */
  }
  
  .dropdown-list27 li {
    padding: 10px 20px;
    color: #153448;
    cursor: pointer;
    transition: background-color 0.2s ease;
    
  }
  
  .dropdown-list27 li:hover {
    /* background-color: #f1f1f1; Light gray on hover */
    background-color: #c9b79b;
    color: #3C5B6F;
  }

  /*for the icon svg animation on dropdown...*/
  .dropdown-icon2{
    transition: transform 0.3s ease;
  }
  .opened2 {
    transform: rotate(180deg);
  }
  