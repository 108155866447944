.who-we-are {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    margin: 0;
    padding: 0;
    color: #333;
    line-height: 1.6;
  }
  
  .header-au {
    text-align: center;
    padding: 60px 20px;
    background: linear-gradient(135deg, #007bff, #6610f2);
    color: white;
  }
  
  .header-au h1 {
    font-size: 3rem;
    margin: 0;
  }
  
  /* .content {
    max-width: 800px;
    margin: 40px auto;
    padding: 20px;
    font-size: 1.25rem;
    color: #555;
    text-align: center;
  } */


  .text-layout-au {
    font-family: 'Helvetica Neue', Arial, sans-serif;
    line-height: 1.8;
    color: #333;
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .text-layout-au h2 {
    font-size: 1.8rem;
    color: #4a4a4a;
    margin-bottom: 10px;
    border-bottom: 2px solid #007bff;
    padding-bottom: 5px;
  }
  
  .text-layout-au p {
    margin: 15px 0;
    font-size: 1.1rem;
    color: #555;
  }
  
  .text-layout-au blockquote {
    font-style: italic;
    font-size: 1.2rem;
    margin: 20px 0;
    padding-left: 20px;
    border-left: 3px solid #007bff;
    color: #444;
  }
  
  .pastor-signature {
    font-style: italic;
    text-align: right;
    margin-top: 30px;
    font-size: 1.2rem;
  }
  
  