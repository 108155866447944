#body-for-cp {
    background: linear-gradient(to bottom, #f7f7f7, #e0e0e0) !important;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    padding-bottom: 50px;
    /* margin-top: 25px; */
}

.container1 {
    margin: 100px;
}

.cont-input{
    width: 50%;
}

@media (max-width: 600px) {

    #contact-form-id {
        min-width: 100%;
        margin-right: 12rem;
        padding: 3rem !important;
    }

    .cont-input{
        width: 120%;
    }
}



#bible-quote {
    padding: 10px;
    font-family: "sinter", sans-serif;
    font-weight: 700;
    font-style: normal;
    text-align: center;
}


.form-container {
    background-color: #835a5a;
    padding: 2rem;
    border-radius: 0.5rem;
    box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 500px;
}

.form-title {
    margin-bottom: 1rem;
    text-align: center;
    color: #1D2B64;
}


#the {
    background-color: #1D2B64;
    color: #fff;
    border-radius: 20px;
    transition: background-color 0.3s ease;
}

#the:hover{
    background-color: #F8C471;
    color: #fff;
}



#back-button {

    padding: 0.75rem 1.5rem;
    background-color: #007bff; 
    color: #ffffff; 
    font-size: 0.75rem;
    font-weight: 500;
    text-align: center;
    text-decoration: none;
    border-radius: 50px; 
    transition: background-color 0.3s ease, box-shadow 0.3s ease;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    margin: 15px;


    
}

#back-button:hover {
    background-color: #0056b3; 
    box-shadow: 0 6px 8px rgba(0, 0, 0, 0.15); 
    text-decoration: none; 
}

#back-button:active {
    background-color: #004494; 
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.2); 
    transform: translateY(2px); 
}
