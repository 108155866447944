.cool-button {
    background: linear-gradient(135deg, #6e8efb, #967E76); /* Gradient background */
    border: none;
    border-radius: 50px; /* Round corners */
    color: white;
    padding: 15px 30px;
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    cursor: pointer;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow for depth */
    transition: all 0.3s ease; /* Smooth transition for hover effects */
    outline: none;
  }
  
  .cool-button:hover {
    background: linear-gradient(135deg, #967E76, #6e8efb); /* Reverse the gradient on hover */
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); /* Increase shadow on hover */
    transform: translateY(-2px); /* Slightly lift the button on hover */
  }
  
  .cool-button:active {
    transform: translateY(1px); /* Press effect */
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2); /* Reduce shadow on press */
  }

  @media (max-width: 600px) {
    .cool-button {
      margin-bottom: 7.5rem;
    }
  }
  