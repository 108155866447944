.dropdown0 {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-button1 {
    background-color: #d3cbb8;
    border: 1px solid #3D2B1F;
    box-shadow: 1px 1px 4px black;
    color: #3D2B1F;
    padding: 10px 20px;
    font-size: 16px;
    /* border: none; */
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    margin-top: 1.5rem;
  }
  
  .dropdown-button1:hover {
    background-color: #e6d9ba;
}
  
  .arrow {
    margin-left: 8px;
  }
  
  .dropdown-list2 {
    position: absolute;
    /* background-color: white;
    border: 1px solid #ccc; */
    background-color: #e6d9ba;
    border: 1px solid #3D2B1F;
    border-radius: 5px;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 5px;
    padding: 0;
    list-style-type: none;
    z-index: 1; /* Ensure it appears on top of other content */
  }
  
  .dropdown-list2 li {
    padding: 10px 20px;
    cursor: pointer;
    border-bottom: 1px solid #3D2B1F;
    transition: background-color 0.2s ease;
  }
  
  .dropdown-list2 li:hover {
    /* background-color: #f1f1f1; Light gray on hover */
    background-color: #c5b89a;
  }

  /* this here is for the animation on dropdown icon*/
  .dropdown-icon1 {
    transition: transform 0.3s ease;
  }
  .opened{
    transform: rotate(180deg);
  }
  