#body-for-prp {
    background: linear-gradient(to bottom,#87CEFA,#F0F8FF );
    color: #333333;
}

#request-container {
    padding: 50px;
    padding-bottom: 125px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

h1 {
    font-family: "sinter", sans-serif;
    font-weight: 700;
    font-style: normal;
}

h2, h3, h4 {
    font-family: "sinter", sans-serif;
    font-weight: 600;
    font-style: normal;
}

#submit-id-pr {
    margin-top: 15px;
}

#prayerRequestForm input{
    width: 30rem;
}
#prayerRequestForm textarea{
    width: 30rem;
}

@media (max-width: 600px) {
    
    #prayerRequestForm input{
        width: 20rem;
    }
    #prayerRequestForm textarea{
        width: 20rem;
    }

}

.red-star {
    color: rgb(175, 17, 17);
}