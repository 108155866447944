/* 

The color pallete:
#153448 - Dark Blue
#3C5B6F - Light Blue
#948979 - Dark Tan
#DFD0B8 - Tan

*/






#full-topic-page {
    color: #DFD0B8;
    background-color: #153448;
    min-height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    padding-top: 3rem;

    font-family: "sinter", sans-serif;
    font-weight: 700;
    font-style: normal;
    padding-bottom: 5rem;
}

.im-red {
    color: rgb(255, 129, 129) !important;
    background-color: darkred !important;
}



#middle-topic-container {
    display: flex;
    background-color: #3C5B6F;
    /* border: 2px solid #E2DAD6; */
    border-radius: 8px;
    padding: 2.5rem;
    gap: 5rem;
    margin-bottom: 3rem;
    max-width: 75%;
}

#right-topic-div h5{
    padding: 1rem;
    border-radius: 8px;
    background-color: #948979;
    font-size: 1rem;
}

/* Progress Indicator Styling */
.progress-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
  }
  .circle {
    width: 14px;
    height: 14px;
    border: 0.5px solid black;
    border-radius: 50%;
    background-color: white;
    margin: 0 5px;
    transition: background-color 0.3s ease;
  }
  .filled {
    background-color: #153448;
    /* background-color: #3498db;  */
  }

@media (max-width: 600px) {

    #full-topic-page{
        padding: 1rem;
    }

    #middle-topic-container{
        display: block;
        min-width: 100%;
    }

    #current-video-on-topic-playlist{
        width: 100%;
        height: auto;
    }

    #upload-text-for-vid {
        display: none;
    }
}

#current-video-on-topic-playlist{
    border: 2px solid #948979;
    border-radius: 5px;
}

.player-buttons-for-topic {
    background-color: #948979;
    border: 1px solid #DFD0B8;
    border-radius: 25px;
    margin: 0.5rem;
    padding: 0.25rem 1.5rem;
    /* box-shadow: 1px 1px 1px #A9D6E5; */
    color: white;
    font-size: 1.25rem;

    position: relative; /* Required for ripple to position correctly */
    overflow: hidden;
}

/* .player-buttons-for-topic:hover{
    background-color: #ada18e;
} */

#t-id-h1{
    color: #DFD0B8;
}

#sub-title-for-curr-id{
    color: #DFD0B8;
}

#description-header-h5{
    color: #DFD0B8;
}

#upload-text-for-vid{
    color: #DFD0B8;
    font-size: 0.8rem;
}

#right-topic-div, h5 {
    color: whitesmoke;
}

 /*THIS IS FOR BUTTON CLICK RIPPLE EFFECT*/
.ripple {
    position: absolute;
    width: 20px;
    height: 20px;
    background: rgba(255, 255, 255, 0.453);
    border-radius: 50%;
    transform: scale(0);
    animation: ripple-animation .9s ease-out forwards;
    color: red;
}
  
@keyframes ripple-animation {
    to {
      transform: scale(20);
      opacity: 0;
    }
}

/* FOR TABLET / IPAD SCREEN WIDTHS */
@media (min-width: 601px) and (max-width: 1024px) {

    #middle-topic-container{
        flex-direction: column;
        gap: 1rem;
    }

    #current-video-on-topic-playlist{
        height: 90%;
        width: auto;
    }

    #t-intro {
        margin: 0rem 5rem;
    }

}


 
  

