.departments-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
  }

  #fake-dep-body {
    height: 100vh;
    background-color: #f6f6e1;
  }

  /* custom grid styling for best grid appearance on MOBILE */
  @media (max-width: 600px) {
    #fake-dep-body {
        height: 100%;
      }
  }
  /* custom grid styling for best grid appearance on TABLET/IPAD */
  @media (min-width: 601px) and (max-width: 1024px) {
    #fake-dep-body {
        height: 100%;
      }
  }

  .departments-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    color: #333;
  }
  
  .departments-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1.5rem;
  }
  
  .department-card {
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .department-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 10px rgba(0, 0, 0, 0.15);
  }
  
  .image-placeholder {
    width: 100%;
    padding-top: 56.25%; /* Aspect Ratio 16:9 */
    background: #eaeaea;
    position: relative;
  }
  
  .department-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  .department-name {
    font-size: 1.2rem;
    font-weight: 600;
    color: #444;
    padding: 1rem;
  }
  